import React from "react"

const Iso2005 = () => {
    return (
        <>
            <div className="row">
                <div className="col">
                    <h2>ISO-8217:2005</h2>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h3>Distillate marine fuels</h3>
                    <div className="table-responsive">
                        <table className="table table-bordered table-sm ">
                            <thead>
                                <tr>
                                    <th scope="col" className="">
                                        Parameter
                                    </th>
                                    <th scope="col" className="text-center ">
                                        Unit
                                    </th>
                                    <th scope="col" className="text-center ">
                                        Test method
                                    </th>
                                    <th scope="col" className="text-center ">
                                        Limit
                                    </th>
                                    <th scope="col" className="text-center ">
                                        DMX
                                    </th>
                                    <th scope="col" className="text-center ">
                                        DMA
                                    </th>
                                    <th scope="col" className="text-center ">
                                        DMB
                                    </th>
                                    <th scope="col" className="text-center ">
                                        DMC (a)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Density at 15 °C</th>
                                    <td className="text-center">kg/m3</td>
                                    <td className="text-center">ISO 3675/12185</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">890</td>
                                    <td className="text-center">900</td>
                                    <td className="text-center">920</td>
                                </tr>
                                <tr>
                                    <th scope="row" className="align-middle">
                                        Viscosity at 40 °C
                                    </th>
                                    <td className="text-center align-middle">
                                        mm²/s <sup>(b)</sup>
                                    </td>
                                    <td className="text-center align-middle">ISO 3104</td>
                                    <td className="text-center">
                                        Max
                                        <br />
                                        Min
                                    </td>
                                    <td className="text-center">
                                        5.5
                                        <br />
                                        1.4
                                    </td>
                                    <td className="text-center">
                                        6.0
                                        <br />
                                        1.5
                                    </td>
                                    <td className="text-center">
                                        11.0
                                        <br />-
                                    </td>
                                    <td className="text-center">
                                        14.0
                                        <br />-
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Micro Carbon Residue at 10%</th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 10370</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">0.3</td>
                                    <td className="text-center">0.3</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                </tr>
                                <tr>
                                    <th scope="row">Micro Carbon Residue</th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 10370</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">0.3</td>
                                    <td className="text-center">2.5</td>
                                </tr>
                                <tr>
                                    <th scope="row">Water</th>
                                    <td className="text-center">% V/V</td>
                                    <td className="text-center">ISO 3733</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">
                                        0.3 <sup>(e)</sup>
                                    </td>
                                    <td className="text-center">0.3</td>
                                </tr>
                                <tr>
                                    <th scope="row">Sulphur</th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 14596/8754</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">1.0</td>
                                    <td className="text-center">1.5</td>
                                    <td className="text-center">
                                        2.0 <sup>(e)</sup>
                                    </td>
                                    <td className="text-center">
                                        2.0 <sup>(e)</sup>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Total sediment existent</th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 10307-1</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">
                                        0.1 <sup>(e)</sup>
                                    </td>
                                    <td className="text-center">0.1</td>
                                </tr>
                                <tr>
                                    <th scope="row">Ash</th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 6245</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">0.01</td>
                                    <td className="text-center">0.01</td>
                                    <td className="text-center">0.01</td>
                                    <td className="text-center">0.05</td>
                                </tr>
                                <tr>
                                    <th scope="row">Vanadium</th>
                                    <td className="text-center">mg/kg</td>
                                    <td className="text-center">ISO 14597/IP 501/470</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">100</td>
                                </tr>
                                <tr>
                                    <th scope="row">Aluminium + Silicon</th>
                                    <td className="text-center">mg/kg</td>
                                    <td className="text-center">ISO 10478/IP 501/470</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">25</td>
                                </tr>
                                <tr>
                                    <th scope="row">Flash point</th>
                                    <td className="text-center">°C</td>
                                    <td className="text-center">ISO 2719</td>
                                    <td className="text-center">Min</td>
                                    <td className="text-center">43</td>
                                    <td className="text-center">60</td>
                                    <td className="text-center">60</td>
                                    <td className="text-center">60</td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Pour point in Summer <sup>(c)</sup>
                                    </th>
                                    <td className="text-center">°C</td>
                                    <td className="text-center">ISO 3016</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">0</td>
                                    <td className="text-center">6</td>
                                    <td className="text-center">6</td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Pour point in Winter <sup>(c)</sup>
                                    </th>
                                    <td className="text-center">°C</td>
                                    <td className="text-center">ISO 3016</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-6</td>
                                    <td className="text-center">0</td>
                                    <td className="text-center">0</td>
                                </tr>
                                <tr>
                                    <th scope="row">Cloud point</th>
                                    <td className="text-center">°C</td>
                                    <td className="text-center">ISO 3015</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">
                                        -16 <sup>(d)</sup>
                                    </td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                </tr>
                                <tr>
                                    <th scope="row">Calculated Cetane Index</th>
                                    <td className="text-center">-</td>
                                    <td className="text-center">ISO 4264</td>
                                    <td className="text-center">Min</td>
                                    <td className="text-center">45</td>
                                    <td className="text-center">40</td>
                                    <td className="text-center">35</td>
                                    <td className="text-center">-</td>
                                </tr>

                                <tr>
                                    <th scope="row">
                                        Used lubricating oil (ULO) <sup>(g)</sup>
                                    </th>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">Free of ULO</td>
                                </tr>
                                <tr>
                                    <th scope="row">Zinc</th>
                                    <td className="text-center">mg/kg</td>
                                    <td className="text-center">IP 501 or 470</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">15</td>
                                </tr>

                                <tr>
                                    <th scope="row">Phosphorous</th>
                                    <td className="text-center">mg/kg</td>
                                    <td className="text-center">IP 501 or 500</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">15</td>
                                </tr>
                                <tr>
                                    <th scope="row">Calcium</th>
                                    <td className="text-center">mg/kg</td>
                                    <td className="text-center">IP 501 or 470</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">30</td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Appearance <sup>(f)</sup>
                                    </th>
                                    <td className="text-center">-</td>
                                    <td className="text-center">See 7.4 and 7.5</td>
                                    <td className="text-center">-</td>
                                    <td colSpan="2" className="text-center">
                                        Clear and bright
                                    </td>
                                    <td className="text-center">
                                        <sup>(f)</sup>
                                    </td>
                                    <td className="text-center">-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col mb-3">
                    <div>
                        <sup>(a)</sup> Note that although predominantly consisting of distillate fuel, the residual oil proportion can
                        be significant.
                    </div>
                    <div>
                        <sup>(b)</sup> 1 mm2/s = 1cSt.
                    </div>
                    <div>
                        <sup>(c)</sup> Purchasers should ensure that this pour point is suitable for the equipment on board, especially
                        if the vessel operates in both the northern and southern hemispheres.
                    </div>
                    <div>
                        <sup>(d)</sup> This fuel is suitable for use without heating at ambient temperatures down to -16 °C.
                    </div>
                    <div>
                        <sup>(e)</sup> A sulfur limit of 1,5 % (m/m) will apply in SO<sub>x</sub> emission control areas designated by
                        the International Maritime Organization, when its relevant protocol enters into force. There may be local
                        variations, for example the EU requires that sulphur content of certain distillate grades be limited to 0,2 %
                        (m/m) in certain applications.
                    </div>
                    <div>
                        <sup>(f)</sup> If the sample is clear and with no visible sediment or water, the total sediment existent and
                        water tests shall not be required. See 7.4 and 7.5.
                    </div>
                    <div>
                        <sup>(g)</sup> A fuel shall be considered to be free of used lubricating oils (ULOs) if one or more of the
                        elements zinc, phosphorus and calcium are below or at the specified limits. All three elements shall exceed the
                        same limits before a fuel shall be deemed to contain ULOs.
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h3>Residual marine fuels</h3>
                    <div className="table-responsive">
                        <table className="table table-bordered table-sm">
                            <thead>
                                <tr>
                                    <th scope="col" className=" align-middle">
                                        Parameter
                                    </th>
                                    <th scope="col" className="text-center  align-middle">
                                        Unit
                                    </th>
                                    <th scope="col" className="text-center  align-middle">
                                        Test method
                                    </th>
                                    <th scope="col" className="text-center  align-middle">
                                        Limit
                                    </th>
                                    <th scope="col" className="text-center  align-middle">
                                        RMA30
                                    </th>
                                    <th scope="col" className="text-center  align-middle">
                                        RMB30
                                    </th>
                                    <th scope="col" className="text-center  align-middle">
                                        RMD80
                                    </th>
                                    <th scope="col" className="text-center  align-middle">
                                        RME180
                                    </th>
                                    <th scope="col" className="text-center  align-middle">
                                        RMF180
                                    </th>
                                    <th scope="col" className="text-center  align-middle">
                                        RMG380
                                    </th>
                                    <th scope="col" className="text-center  align-middle">
                                        RMH380
                                    </th>
                                    <th scope="col" className="text-center  align-middle">
                                        RMK380
                                    </th>
                                    <th scope="col" className="text-center  align-middle">
                                        RMH700
                                    </th>
                                    <th scope="col" className="text-center  align-middle">
                                        RMK700
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Density at 15 °C</th>
                                    <td className="text-center">kg/m3</td>
                                    <td className="text-center">ISO 3675/12185</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">960</td>
                                    <td className="text-center">975</td>
                                    <td className="text-center">980</td>
                                    <td colSpan="4" className="text-center">
                                        991
                                    </td>
                                    <td className="text-center">1010</td>
                                    <td className="text-center">991</td>
                                    <td className="text-center">1010</td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Viscosity at 50 °C <sup>(b)</sup>
                                    </th>
                                    <td className="text-center">mm²/s</td>
                                    <td className="text-center">ISO 3104</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">30</td>
                                    <td className="text-center">30</td>
                                    <td className="text-center">80</td>
                                    <td className="text-center">180</td>
                                    <td className="text-center">180</td>
                                    <td className="text-center">380</td>
                                    <td className="text-center">380</td>
                                    <td className="text-center">380</td>
                                    <td className="text-center">700</td>
                                    <td className="text-center">700</td>
                                </tr>
                                <tr>
                                    <th scope="row">Water</th>
                                    <td className="text-center">% V/V</td>
                                    <td className="text-center">ISO 3733</td>
                                    <td className="text-center">Max</td>
                                    <td colSpan="10" className="text-center">
                                        0.5
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Micro Carbon Residue</th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 10370</td>
                                    <td className="text-center">Max</td>
                                    <td colSpan="2" className="text-center">
                                        10
                                    </td>
                                    <td className="text-center">14</td>
                                    <td className="text-center">15</td>
                                    <td className="text-center">20</td>
                                    <td className="text-center">18</td>
                                    <td colSpan="4" className="text-center">
                                        22
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Sulphur <sup>(c)</sup>
                                    </th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 14596/8754</td>
                                    <td className="text-center">Max</td>
                                    <td colSpan="2" className="text-center">
                                        3.5
                                    </td>
                                    <td className="text-center">4</td>
                                    <td colSpan="7" className="text-center">
                                        4.5
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="align-middle">
                                        Total sediment potential
                                    </th>
                                    <td className="text-center align-middle">% m/m</td>
                                    <td className="text-center align-middle">ISO 10307-2</td>
                                    <td className="text-center align-middle">Max</td>
                                    <td colSpan="10" className="text-center align-middle">
                                        0.1
                                    </td>
                                </tr>

                                <tr>
                                    <th scope="row">Ash</th>
                                    <td className="text-center">% m/m</td>
                                    <td className="text-center">ISO 6245</td>
                                    <td className="text-center">Max</td>
                                    <td colSpan="4" className="text-center">
                                        0.1
                                    </td>
                                    <td colSpan="6" className="text-center">
                                        0.15
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="align-middle">
                                        Vanadium
                                    </th>
                                    <td className="text-center align-middle">mg/kg</td>
                                    <td className="text-center align-middle">ISO 14597/IP 501/IP 470</td>
                                    <td className="text-center align-middle">Max</td>
                                    <td colSpan="2" className="text-center align-middle">
                                        150
                                    </td>
                                    <td className="text-center align-middle">350</td>
                                    <td className="text-center align-middle">200</td>
                                    <td className="text-center align-middle">500</td>
                                    <td className="text-center align-middle">300</td>
                                    <td colSpan="4" className="text-center align-middle">
                                        600
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="align-middle">
                                        Aluminium + Silicon
                                    </th>
                                    <td className="text-center align-middle">mg/kg</td>
                                    <td className="text-center align-middle">ISO 10478/IP 501/IP 470</td>
                                    <td className="text-center align-middle">Max</td>
                                    <td colSpan="10" className="text-center align-middle">
                                        80
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Flash point</th>
                                    <td className="text-center">°C</td>
                                    <td className="text-center">ISO 2719</td>
                                    <td className="text-center">Min</td>
                                    <td colSpan="10" className="text-center">
                                        60
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Pour point in Summer <sup>(f)</sup>
                                    </th>
                                    <td className="text-center">°C</td>
                                    <td className="text-center">ISO 3016</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">6</td>
                                    <td className="text-center">24</td>
                                    <td colSpan="8" className="text-center">
                                        30
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Pour point in Winter <sup>(f)</sup>
                                    </th>
                                    <td className="text-center">°C</td>
                                    <td className="text-center">ISO 3016</td>
                                    <td className="text-center">Max</td>
                                    <td className="text-center">0</td>
                                    <td className="text-center">24</td>
                                    <td colSpan="8" className="text-center">
                                        30
                                    </td>
                                </tr>
                                <tr>
                                    <th className="align-middle" scope="row">
                                        Used lubricating oil (ULO):
                                        <br />
                                        <br />
                                        Zinc
                                        <br />
                                        Phosphorus
                                        <br />
                                        Calcium
                                    </th>
                                    <td className="text-center align-middle">mg/kg</td>
                                    <td className="text-center align-middle">
                                        <br />
                                        <br />
                                        IP 501/IP 470
                                        <br />
                                        IP 501/IP 500
                                        <br />
                                        IP 501/IP 470
                                    </td>
                                    <td className="text-center align-middle">Max</td>
                                    <td className="text-center align-middle" colSpan="10">
                                        The fuel shall be free from ULO <sup>(d)</sup>.
                                        <br />
                                        <br />
                                        15
                                        <br />
                                        15
                                        <br />
                                        30
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div>
                        <sup>(a)</sup> Annex C gives a brief viscosity/temperature table, for information purposes only. 1 mm
                        <sup>2</sup>/s = 1 cSt.
                    </div>
                    <div>
                        <sup>(b)</sup> Purchasers should ensure that this pour point is suitable for the equipment on board, especially
                        if the vessel operates in both the northern and southern hemispheres.
                    </div>
                    <div>
                        <sup>(c)</sup> A sulfur limit of 1,5 % (m/m) will apply in SO<sub>x</sub> emission control areas designated by
                        the International Maritime Organization, when its relevant protocol comes into force. There may be local
                        variations.
                    </div>
                    <div>
                        <sup>(d)</sup> A fuel shall be considered to be free of ULO if one or more of the elements zinc, phosphorus and
                        calcium are below or at the specified limits. All three elements shall exceed the same limits before a fuel
                        shall be deemed to contain ULO.
                    </div>
                    <div>
                        <sup>(e)</sup> See Annex H.
                    </div>
                    <div>
                        <sup>(f)</sup> Purchasers shall ensure that this pour point is suitable for the equipment on board, especially
                        if the ship operates in cold climates.
                    </div>

                    <p className="mt-3">
                        More information on the International Organization for Standardization website:{" "}
                        <a href="https://www.iso.org/standard/36365.html" target="_blank" rel="noreferrer">
                            ISO 8217:2005
                        </a>
                        .
                    </p>
                </div>
            </div>
        </>
    )
}

export default Iso2005
