import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Iso2005 from "../../../components/energy-logistics/iso-8217/2005"
import Iso2010 from "../../../components/energy-logistics/iso-8217/2010"
import Iso2012 from "../../../components/energy-logistics/iso-8217/2012"

const Iso8217 = () => {
    return (
        <Layout>
            <Seo title="ISO-8217" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3 ">
                    <div className="col border-bottom border-1 border-dark">
                        <div className="row g-0 ">
                            <div className="col">
                                <h1>ISO-8217</h1>
                            </div>
                            <div className="col-12 col-sm-6">
                                <ul className="nav nav-pills justify-content-start justify-content-sm-end" id="myTab">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link active"
                                            id="panel-2005"
                                            data-bs-toggle="tab"
                                            data-bs-target="#tab2005"
                                            type="button"
                                            role="tab"
                                            aria-controls="2005"
                                            aria-selected="true"
                                        >
                                            2005
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="panel-2010"
                                            data-bs-toggle="tab"
                                            data-bs-target="#tab2010"
                                            type="button"
                                            role="tab"
                                            aria-controls="2010"
                                            aria-selected="true"
                                        >
                                            2010
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="panel-2012"
                                            data-bs-toggle="tab"
                                            data-bs-target="#tab2012"
                                            type="button"
                                            role="tab"
                                            aria-controls="2012"
                                            aria-selected="true"
                                        >
                                            2012
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row ">
                    <div className="col">
                        <p>
                            ISO-8217 is a specification of marine fuels by the International Organization for Standardization. The
                            standard is constantly evolving. Select a year to switch between the editions. We have taken great care to
                            collect the information, however we assume no responsibility for any errors or omissions. The content is
                            provided for informational purposes only.
                        </p>
                    </div>
                </div>

                <div className="tab-content">
                    <div className="tab-pane active" id="tab2005" role="tabpanel" aria-labelledby="panel-2005">
                        <Iso2005 />
                    </div>
                    <div className="tab-pane" id="tab2010" role="tabpanel" aria-labelledby="panel-2010">
                        <Iso2010 />
                    </div>
                    <div className="tab-pane" id="tab2012" role="tabpanel" aria-labelledby="panel-2012">
                        <Iso2012 />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Iso8217
